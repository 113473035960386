var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Layout',[_c('div',{attrs:{"id":"section"}},[_c('div',{staticClass:"row",attrs:{"id":"place_content"}},[_c('div',{staticClass:"col-xl-2 ps-0 position-relative transition_class",class:{ fullWidth: _vm.isClick }},[_c('sidebar',{ref:"sideBar",on:{"filterData":_vm.listenFromSideBar}}),_c('i',{staticClass:"fas position-absolute top-0 end-0 px-4 f-16 primaryColor pointer",class:_vm.isClick == false ? 'fa-times' : 'fa-bars',on:{"click":function($event){_vm.isClick = !_vm.isClick}}})],1),_c('div',{staticClass:"col-xl-10",class:{ 'col-xl-12': _vm.isClick }},[_c('PageHeader',{attrs:{"items":_vm.items,"title":_vm.title}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md"},[_c('ul',{staticClass:"nav nav-pills",attrs:{"role":"tablist"}},_vm._l((_vm.tabs),function(tab,index){return _c('li',{key:index,staticClass:"nav-item"},[_c('a',{staticClass:"nav-link text-dark d-flex align-items-center",class:{
                    gradientColor: _vm.currentTabComponent === tab.status,
                    'text-white': _vm.currentTabComponent === tab.status,
                  },attrs:{"id":tab.id,"data-toggle":"tab","href":'#' + _vm.currentTabComponent,"aria-controls":"shift-listing","role":"tab","aria-selected":"true"},on:{"click":function($event){return _vm.switchTab(tab.status)}}},[_c('span',{staticClass:"d-sm-block"},[_vm._v(_vm._s(tab.desc))])])])}),0)])]),_c('div',{staticClass:"card mt-3"},[_c('div',{staticClass:"card-body under-tabs"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"tab-content"},[(_vm.currentTabComponent == 'RosterShifts')?_c('div',[_c('div',{staticClass:"table-responsive mb-0"},[_c('b-table',{attrs:{"table-class":"table table-centered datatable mt-3 table-bordered","thead-tr-class":"bg-transparent","items":_vm.rosterShifts,"fields":_vm.fields,"responsive":"sm","per-page":0,"current-page":_vm.currentPage,"sort-desc":_vm.sortDesc,"show-empty":"","busy":_vm.loading},on:{"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [(_vm.loading)?_c('APILoader',{staticClass:"align-middle",attrs:{"loading":_vm.loading}}):_vm._e()]},proxy:true},{key:"cell(area_roles)",fn:function(data){return [(data.item.area_roles.length > 0)?[_c('p',{staticClass:"text-capitalize"},[_vm._v(" "+_vm._s(_vm.rolesToString(data.item.area_roles))+" ")])]:[_c('p',[_vm._v("N/A")])]]}},{key:"cell(selected_slots)",fn:function(data){return [_c('a',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-xl",modifiers:{"modal-xl":true}}],staticClass:"primaryColor",on:{"click":function($event){return _vm.RosterScheduleShifts(
                                data.item.shift_timeline,
                                data.item.area_roles
                              )}}},[_vm._v("View Selected Shifts")])]}},{key:"cell(vacant_slots)",fn:function(data){return [_c('a',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-xl",modifiers:{"modal-xl":true}}],staticClass:"primaryColor",on:{"click":function($event){_vm.shiftsRoster(data.item, (_vm.empty_slot = true))}}},[_vm._v("View Vacant Shifts")])]}}],null,false,43271393)})],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"dataTables_paginate paging_simple_numbers float-end"},[_c('ul',{staticClass:"pagination pagination-rounded"},[_c('b-pagination',{attrs:{"total-rows":_vm.rows,"per-page":_vm.perPage},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)])])]),_c('viewRosterShifts',{ref:"rosterShifts"})],1):_vm._e(),(_vm.currentTabComponent == 'vacant_shifts')?_c('allVacantShiftsListing',{ref:"vacantShiftsRef",attrs:{"vacantPropFiltelrs":_vm.table_data}}):_vm._e(),(_vm.currentTabComponent == 'schedule_shifts')?_c('allScheduleShiftsListing',{ref:"scheduleShiftsRef",attrs:{"schedulePropFiltelrs":_vm.table_data}}):_vm._e(),(_vm.currentTabComponent == 'shift_per_employee_roster')?_c('ShiftsPerEmployeeRosater',{ref:"shift_per_employee_roster",attrs:{"propFiltelrs":_vm.table_data}}):_vm._e()],1)])])])])],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }