<script>
import allVacantShiftsListing from "./allVacantShiftsListing.vue";
import allScheduleShiftsListing from "./allScheduleShiftsListing.vue";

import excelParser from "@/mixins/excel-parser.js";
import $ from "jquery";
export default {
  props: {
    propFiltelrs: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  mixins: [excelParser],

  data() {
    return {
      
      exportList: [],
      loading: false,
      length: 10,
    };
  },
  components: {
    allVacantShiftsListing,
    allScheduleShiftsListing,
  },
  methods: {
    
  },
  mounted() {},
  computed: {},

  watch: {},

  middleware: "authentication",
};
</script>

<template>
  <div class="">
    <div class="card-body pt-0">
      <div id="modal-parent">
        <h5>Schedule Shifts</h5>
        <allScheduleShiftsListing
          ref="scheduleShiftsRef"
          :schedulePropFiltelrs="propFiltelrs"
        />

        <h5>Vacant Shifts</h5>
        <allVacantShiftsListing
          ref="vacantShiftsRef"
          :vacantPropFiltelrs="propFiltelrs"
        />
      </div>
    </div>
  </div>
</template>
